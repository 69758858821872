.table {
  :global {
    .ant-table-thead > tr > th {
      // background: transparent;
      background : #009d4b;
      color : white;
      padding: 10px 10px;
      // height: 30px;
    }
    .ant-table-tbody > tr > td {
      padding: 10px 10px;
    }
  }
}

.row {
  &:hover {
    cursor: pointer;
  }
}
